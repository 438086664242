import revive_payload_client_jmzMIxlc0S from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0KaYk9jr4s from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wedjEllxQu from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_lmpMwNjkQp from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.3.4_vue@3.4.32/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8G0GbWfhkl from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vpcpqDsgJd from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GirFFhbPey from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_npxuxCIqYq from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kcJz01OKeB from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.5.3_vue@3.4.32/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/library-website-nuxt/library-website-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_aGmxXZWX6I from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_VrYKuP1kuW from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.3_vue@3.4.32/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_3W1kP6H59v from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt-graphql-request@7.0.5_graphql@16.9.0/node_modules/nuxt-graphql-request/dist/runtime/plugin.mjs";
import add_library_website_vue_components_yLAStJOsBJ from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/add-library-website-vue-components.js";
import add_vue_skip_to_TpqMnYriJH from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/add-vue-skip-to.js";
import browser_events_client_WdgNHzxIYE from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/browser-events.client.js";
import craft_xgLlYngLwv from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/craft.js";
import data_api_6nrdODAL7t from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/data-api.js";
import get_headers_client_gEno0Gq67v from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/get-headers.client.js";
import getScrollInfo_client_8NhEI34iau from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/getScrollInfo.client.js";
import scrape_formid_client_L5cBRTlafH from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/scrape-formid.client.js";
import update_library_alert_client_kn0IiQ5VL4 from "/home/runner/work/library-website-nuxt/library-website-nuxt/plugins/update-library-alert.client.js";
export default [
  revive_payload_client_jmzMIxlc0S,
  unhead_0KaYk9jr4s,
  router_wedjEllxQu,
  _0_siteConfig_lmpMwNjkQp,
  payload_client_8G0GbWfhkl,
  navigation_repaint_client_vpcpqDsgJd,
  check_outdated_build_client_GirFFhbPey,
  chunk_reload_client_npxuxCIqYq,
  plugin_vue3_kcJz01OKeB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_aGmxXZWX6I,
  plugin_VrYKuP1kuW,
  plugin_3W1kP6H59v,
  add_library_website_vue_components_yLAStJOsBJ,
  add_vue_skip_to_TpqMnYriJH,
  browser_events_client_WdgNHzxIYE,
  craft_xgLlYngLwv,
  data_api_6nrdODAL7t,
  get_headers_client_gEno0Gq67v,
  getScrollInfo_client_8NhEI34iau,
  scrape_formid_client_L5cBRTlafH,
  update_library_alert_client_kn0IiQ5VL4
]