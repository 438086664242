import routerOptions0 from "/home/runner/work/library-website-nuxt/library-website-nuxt/node_modules/.pnpm/nuxt@3.12.3_@types+node@18.19.40_eslint@8.57.0_sass@1.77.8_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/library-website-nuxt/library-website-nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}